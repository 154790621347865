// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-2-js": () => import("./../../../src/pages/archive2.js" /* webpackChunkName: "component---src-pages-archive-2-js" */),
  "component---src-pages-archive-3-js": () => import("./../../../src/pages/archive3.js" /* webpackChunkName: "component---src-pages-archive-3-js" */),
  "component---src-pages-archive-4-js": () => import("./../../../src/pages/archive4.js" /* webpackChunkName: "component---src-pages-archive-4-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pensieve-index-js": () => import("./../../../src/pages/pensieve/index.js" /* webpackChunkName: "component---src-pages-pensieve-index-js" */),
  "component---src-pages-pensieve-tags-js": () => import("./../../../src/pages/pensieve/tags.js" /* webpackChunkName: "component---src-pages-pensieve-tags-js" */),
  "component---src-pages-project-archive-js": () => import("./../../../src/pages/project_archive.js" /* webpackChunkName: "component---src-pages-project-archive-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

